import React from 'react';
import { GridContainer, GridItem, H2 } from '@tuunetech/tuune-components';
import {
  PostsList,
  // Hormones101,
} from '../../PostsList';
import { Post } from 'types';
import { TagPostsContainer } from './styles';

interface CategoryLayoutProps {
  categoryName: string;
  categoryUid: string;
  posts: Post[];
  totalPages: number;
  currentPage: number;
}

export const CategoryLayout: React.FC<CategoryLayoutProps> = ({
  categoryName,
  posts,
  categoryUid,
  totalPages,
  currentPage,
}) => {
  return (
    <>
      <TagPostsContainer>
        <GridContainer>
          <GridItem xs={12}>
            <H2 align="center">{categoryName}</H2>
          </GridItem>
        </GridContainer>
        <PostsList
          posts={posts}
          path={`/${categoryUid}`}
          count={totalPages}
          page={currentPage}
        />
        {/*<Hormones101 />*/}
      </TagPostsContainer>
    </>
  );
};
