import React, { useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { PostsListData, PrismicCategoryData, SEODStaticData } from 'types';
import { CategoryLayout } from '../components/Category/CategoryLayout';
import { Layout } from '../components/shared/Layout';
import { constructSEO } from 'utils/seo';

type PostsListPageContentType = {
  categoryName: string;
  uid: string;
  numPages: number;
  currentPage: number;
};

type CategoryProps = PostsListData & {
  prismicCategory: PrismicCategoryData;
  SEO: SEODStaticData;
};

const Category: React.FC<PageProps<CategoryProps, PostsListPageContentType>> =
  ({ data, pageContext, location }) => {
    const seoData = useMemo(
      () =>
        constructSEO(data.prismicCategory.data, data.SEO, location.pathname),
      [data.prismicCategory.data, data.SEO, location.pathname],
    );

    return (
      <Layout path={location.pathname} seoData={seoData}>
        <CategoryLayout
          currentPage={pageContext.currentPage}
          posts={data.allPrismicBlogPost.nodes}
          categoryName={pageContext.categoryName}
          categoryUid={pageContext.uid}
          totalPages={pageContext.numPages}
        >
          {location.hostname}
        </CategoryLayout>
      </Layout>
    );
  };

export const query = graphql`
  query ($uid: String!, $skip: Int!, $limit: Int!) {
    allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: $uid } } } }
      sort: { fields: data___release_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PostPreviewData
        data {
          hero_image {
            fluid(imgixParams: { fit: "crop", ar: "2.2:1" }, maxWidth: 385) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
        }
      }
    }

    prismicCategory(uid: { eq: $uid }) {
      ...PrismicCategorySeoData
    }

    SEO: site {
      siteMetadata {
        description
        siteUrl
        title
        twitterUsername
      }
    }
  }
`;

export default Category;
