import styled from 'styled-components';
import { H2, Container } from '@tuunetech/tuune-components';

export const TagPostsContainer = styled(Container)`
  margin: 73px;

  ${H2} {
    margin-bottom: 80px;
    text-transform: capitalize;
  }

  @media ${({ theme }) => theme.media.tablet} {
    margin: 104px auto;

    ${H2} {
      margin-bottom: 104px;
    }
  }
`;
